<template>
    <div class='app' v-if="show">
        <div class='main'>
            <div class='content'>
                <div v-if='show2'>
                    <div class='kj_top'>
                        <div>第<span>{{kjdata[i].Qi}}</span>期香港六合彩开奖</div>
                        <span class='djs'>
                            <span v-if="i!=2">倒计时</span>
                            <span v-if="i==2">截止</span>:{{djt}}
                        </span>
                        <a target="_blank" :href='his[i]' style="color:#004fff">历史记录</a> 
                    </div>
                    <ul :class='tabList[i].cod'>
                        <li v-for="(item,index) in pma[i]" :key="index">
                            <p :class='item.style'>{{item.number}}</p>
                            <p class='sx'>{{item.sx}}<span v-if="i!=2">/{{item.nim}}</span></p>
                        </li>
                        <div v-if="i!=2" style="font-size:36px">+</div>
                        <li :class='tma[i].style'>
                            <p :class='tma[i].style'>{{tma[i].number}}</p>
                            <p class='sx'>{{tma[i].sx}}<span v-if="i!=2">/{{tma[i].nim}}</span></p>
                        </li>
                    </ul>
                    <div class="next_kj">
                        <p :style="{'background':i==3?'#ff0':''}">
                            <span v-if='i==1' class='clock'></span>
                            第<b>{{kjdata[i].Nq}}</b>期 {{kjdata[i].Moon}}月{{kjdata[i].Day}}日 {{kjdata[i].Week}} {{kjdata[i].Time}}
                            <a v-if='i==0' target="_blank" href="https://00853six.com/h5/#/lotteryVideo">开奖视频</a>
                            <span style="margin-left:8%" v-if="i==3" onclick="javascript:location.reload();" class="kj-res">刷新</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style lang="less" scoped>
    .main{
        padding:1px;
        .nav{
            display: flex;
            border-left: 1px solid #ff6c02;
            .tab{
                cursor: pointer;
                line-height: 26px;
                font-size: 12px;
                padding: 0 2px;
                border: 1px solid #acacac;
                margin-bottom: -1px;
                border-bottom: none;
                color: #9c9c9c;
                margin-left: -1px;
            }
            .act{
                font-weight: 600;
                background: #fff;
                z-index: 1;
                border-color: #ff6c02;
                color:#ff6c02;
            }
        }
        .content{
                border: 1px solid #ff6c02;height:135px;
                font-size: 15px;
            .link{
                display: flex;
                justify-content: space-around;
                a{
                    font-size: 18px;
                    color: #3c79ff;
                    font-weight: 600;
                    text-decoration: none;
                    line-height: 34px;
                }
            }
            .kj_top{
                line-height: 30px;
                display: flex;margin: 0 3px;
                justify-content: space-between;
                .djs{border:1px solid;color:#f00;height: 20px;line-height: 22px;margin-top: 4px;padding: 0 2px;}
                span,a{
                    color: #ff2121;
                    font-weight: 600;
                }
            }
            ul{
                border-bottom: 1px solid #bebebe;
                display: flex;padding-bottom: 4px;
                li{
                    width: calc(100%/7);
                    p:first-child{
                        background-repeat: no-repeat;
                        background-size: 130px;
                        margin: 0 auto;
                        font-weight: 600;
                    }
                }
            }
            .sx{
                font-size: 15px;color: #040404;line-height: 26px;font-family: '';
            }
            .next_kj{
                line-height: 32px;font-family: '';
                .clock{background-image:url(../assets/clock.png);background-size: 100%;width: 20px;height: 20px;position: relative;display: inline-block;top: 4px;}
                b,span{color:#f00;}
                a{margin-left: 5%;background: #f00;color: #fff;padding: 2px 4px;border-radius: 4px;}
            }
        }
    }
    .xjp{
        padding-bottom: 0!important;
        li{
            p:first-child{
                border-radius: 50%;color: #fff;border: 2px dotted #fff;
                width: 40px;
                height: 40px;
                font-size: 26px;
                line-height: 42px;
                box-shadow: 1px 2px 4px 0px #555555;
            }
            .red{background:#f00}.green{background:#1dc61d}.blue{background:#00b9ff}
        }
    } 
    .five{
        justify-content: space-around;padding: 0 1%;
        >.green{box-shadow: #05e50b 0px 0px 5px 1px;}
        >.red{box-shadow: #ff0707 0px 0px 5px 1px;}
        >.blue{box-shadow: #22b8ff 0px 0px 5px 1px;}
        li{
            width: 12.5%!important;
            height: 66px;
            border-radius: 6px;
            box-shadow: 0px 0px 3px #636363;
            background: linear-gradient(#ffffff,#e2e2e2);
            p:first-child{
                background-image: url(../assets/am_ball.png);
                line-height: 41px;width: 40px;height: 40px;
                text-indent: -1px;font-size: 18px;
                margin-top: 3px!important;
                margin-bottom: -2px!important;
            }
            .blue{background-position: -91px 0;}
            .green{background-position: -45px 0;}
        }
        li:last-child{margin-left: 2%;}
    }
    .tw{
        padding-left: 5px;padding-right: 5px;
        li{
            p{border: 1px solid #b5b5b5;}
            p:first-child{height: 36px;color:#fff;line-height: 42px;font-size: 22px;}
            .red{background:#f00}.green{background:#1dc61d}.blue{background:#00b9ff}
        }
    } 
    .hk li{
        p:first-child{
            background-image: url(../assets/xg_ball.png);
            line-height: 35px;width: 40px;height: 40px;
            text-indent: -2px;font-size: 18px;
        }
        .blue{background-position: -91px 0;}
        .green{background-position: -45px 0;}
    }
    .am li{
        p:first-child{
            background-image: url(../assets/am_ball.png);
            line-height: 41px;width: 40px;height: 40px;
            text-indent: -1px;font-size: 18px;
        }
        .blue{background-position: -91px 0;}
        .green{background-position: -45px 0;}
    } 
</style>
<script>
export default {
    data() {
        return {
            i:0,
            tabList:[
                {name:'澳门六合彩',cod:'am',time:['21','14','21','40']},
                {name:'香港六合彩',cod:'hk',time:['21','00','21','40']},
                // {name:'五分六合彩',cod:'five',time:['00','00','23','59']},
                // {name:'台湾六合彩',cod:'tw',time:['20','46','20','59']},
                // {name:'新加坡六合彩',cod:'xjp',time:['18','28','18','50']},
            ],
            his:['https://48kk55.com:443/Kj/index.html',
            'https://kkj.135hk.site/api/xg/am_chars.html#',
            'https://kkj.135hk.site/api/wf/am_chars.html',
            'https://kkj.135hk.site/api/tw/am_chars.html',
            'https://kkj.135hk.site/api/xjp/am_chars.html'],
            kjdata:[],
            pma:[],
            tma:[],
            show:0,
            show2:1,
            host:'http://172.247.179.138:7776/api?name=',
            kjt:[' 21:34',' 21:32','',' 20:50',' 18:35'],
            djt:'',
        }
    },
    mounted () {
        // 检测当前时间，在开奖时间段内则启用自动请求
        this.timer = setInterval(this.time_range, 3000);
        this.timer2 = setInterval(this.djs,1000);
        // this.$nextTick(()=>{
        //     var hm = document.createElement('script');
        //     hm.src = 'https://s22.cnzz.com/z_stat.php?id=1263859280&web_id=1263859280';
        //     var s = document.getElementsByTagName("script")[0];
        //     s.parentNode.insertBefore(hm, s);
        // });
    },
    beforeDestroy() {
        clearInterval(this.timer);
        clearInterval(this.timer2);
    },
    created() {
        // 识别请求的开奖类型
        let url = window.location.search;
        let type = 0;
        switch (url) {
            case '?xg':
                type = 1;
                break;
            case '?tw':
                type = 3;
                break;
            case '?wf':
                type = 2;
                break;
            case '?xjp':
                type = 4;
                break;
            default:
                type = 1
        }
        this.i=type;
        this.firstGet(type);
    },
    methods: {
        //倒计时
        djs(){
            var i = this.i;
            if(i==2){
                
                let nm = new Date().getMinutes();
                let ns = new Date().getSeconds();
                let dm = 5-nm%5;
                let ds = 60-ns;
                if(ns!=0){
                   dm = dm - 1; 
                };
                ds = ds<10?'0'+ds:ds;
                this.djt = '0'+dm+':'+ds;
            }else{
                var nm = (new Date()).getMonth()+1;
                var ny = this.kjdata[i].Moon<nm?(new Date()).getFullYear()+1:(new Date()).getFullYear();

                var ktime = ny+'/'+this.kjdata[i].Moon+'/'+this.kjdata[i].Day+this.kjt[i];
                var kt = (new Date(ktime)).getTime()/1000;
                var nt = Math.round(new Date() / 1000);

                var t = kt-nt;
                var h = Math.floor(t/3600);
                var m = Math.floor(t%3600/60);
                var s = Math.floor(t%60);
                var isN = isNaN(this.tma[i].number);
                if(h<0||m<0||s<0){
                    this.djt = '00:00:00';
                }else{
                    h = h<10?'0'+h:h;
                    m = m<10?'0'+m:m;
                    s = s<10?'0'+s:s;
                    this.djt = h+':'+m+':'+s;
                }
            }
        },
        // 时间检测函数
        time_range(){
            var b = new Date();
            var e = new Date();
            var n = new Date();
            var type = this.i;
            var tplist = this.tabList;
            
            let kjtime = tplist[type]['time']; 
            let name = tplist[type]['name'];
            let code = tplist[type]['cod'];
            
            var str = '['+name+']开奖时间段'+kjtime[0]+'：'+kjtime[1]+'——'+kjtime[2]+'：'+kjtime[3]+'，';
            
            b.setHours(kjtime[0]);
            b.setMinutes(kjtime[1]);
            e.setHours(kjtime[2]);
            e.setMinutes(kjtime[3]);
            
            if (n.getTime() - b.getTime() >= 0 && n.getTime() - e.getTime() <= 0) {
                str = str + '现在处于开奖时间段，每3秒自动获取开奖信息。';
                console.log(str);
                this.getData(type);
            } else {
                str = str + '现在不在开奖时间段。';
                console.log(str);
            }
        },
        //加载数据
        setData(data,i){
            data['Time'] = data['Time'].replace('点',':').replace('分','');
            this.$set(this.kjdata,i,data);
            
            var d = data.Data;
            var arr = [];
            for(var k=0;k<6;k++){
                arr[k] = d[k+1];
            }
            this.$set(this.pma,i,arr);
            this.$set(this.tma,i,d[7]);
        },
        //加载五分数据
        setWfData(d){
            console.log(d);
        },
        //生成五分彩请求连接
        wfurl(){
            let t = new Date();
            let y = t.getFullYear();
            let m = t.getMonth()+1;
            let h = t.getHours();
            m = m<10?'0'+m:m;
            let d = t.getDate();
            if(h<6){
                if(d==1){
                    m = m-1;
                    if(m<10){
                        m = '0'+m
                    }
                    let t1 = Date.parse(new Date());
                    t2 = t1 - 86400000;
                    t3 = new Date(t2);
                    d = t3.getDate();
                }else{
                    d = d-1; 
                }
            }
            d = d>=10?d:'0'+d;
            let time = ''+y+m+d;
            return 'https://jsapi.895kj.com/Json/WuSix.Aspx?data='+time;
        },
        //请求数据
        getData(n) {
            let t = new  Date().getTime()*1000;
            let url = ''+this.host+this.tabList[n].cod;
            this.$axios.get(url).then(res=>{ 
                this.setData(res.data.data,n);
                this.show2 = 1;
                this.i = n;
                this.djs();
                });
        },  
        //切换
        change(n){
            this.show2 = 0;
            this.getData(n);
            // this.i = n;
            // this.djs();
        },
        //初始化
        firstGet(i){
            // let url4 = this.wfurl();
            let url1 = this.host+'am';
            let url2 = this.host+'hk';
            let url3 = this.host+'five';
            let url4 = this.host+'tw';
            let url5 = this.host+'xjp';

            let url = this.host+this.tabList[i].cod;

            this.$axios.get(url).then(res=>{ 
                this.setData(res.data.data,i);
                this.show=1;
                });
            // this.$axios.get('/proxy').then(res=>{
            //     console.log(res);
            // })
            // Promise.all([
            //         new Promise((resolve,reject)=>{
            //             this.$axios(url1).then((res)=>{
            //                 this.setData(res.data.data,0);
            //                 resolve(res);
            //             })
            //         }),
            //         new Promise((resolve,reject)=>{
            //             this.$axios(url2).then((res)=>{
            //                 this.setData(res.data.data,1);
            //                 resolve(res);
            //             })
            //         }),
            //         new Promise((resolve,reject)=>{
            //             this.$axios(url3).then((res)=>{
            //                 this.setData(res.data.data,2);
            //                 resolve(res);
            //             })
            //         }),
            //         new Promise((resolve,reject)=>{
            //             this.$axios(url4).then((res)=>{
            //                 this.setData(res.data.data,3);
            //                 resolve(res);
            //             })
            //         }),
            //         new Promise((resolve,reject)=>{
            //             this.$axios(url5).then((res)=>{
            //                 this.setData(res.data.data,4);
            //                 resolve(res);
            //             })
            //         }),
            //     ]).then(res=> {
            //     this.show=1;               
            // })
        }    
    }
}
</script>